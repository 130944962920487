import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql} from "gatsby";

export const data = graphql`query SupportQuery {
    file(relativePath: {eq: "NemoBudgetPlanner.pdf"}) {
      publicURL
    }
  }
  `

const HelpRepaymentPage = ({data}) => (
    <Layout>
        <Seo title={'Help With Repayments For Our Customers | Nemo Personal Finance'} description={'If you are a customer who is having difficulty making their repayments and want to know what both you and Nemo can do to help the situation, click here.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/support/extra-help-when-you-need-it">Extra help when you need it</a></li>
                        <li><a href="/support/homes-for-ukraine">Homes for Ukraine</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Support</h1>
                            </div>
                            <div className="bot"></div>
                        </div>


                        <p className="leading">If you’re worried about making your mortgage payments now or in the future, there are ways we
                        can help.</p>
                        <p className="leading">One of our Customer Support Consultants can work with you to help find a solution. We
                        understand it can be daunting, but the sooner you get in touch with us, the better.</p>
                        <strong>Important: talking to us about mortgage payment support does not impact your credit file</strong>
                        <div><br /></div>
                        <strong>How to get in touch</strong><div><br /></div>
                        <ul>
                            <li>Call us on 0800 612 9982 - between 9:30am and 5pm, Monday to Friday.</li>
                            <li>Email us at <a href="mailto:customersupportteam@nemoloans.co.uk">customersupportteam@nemoloans.co.uk.</a></li>
                            <li>Write to us at: Nemo Personal Finance Ltd, Principality House, The Friary, Cardiff,
                            CF10 3FA.</li>
                        </ul>
                        <strong>You can seek free advice </strong><div><br /></div>
                        <p>Our Customer Support Consultants can provide you with support, but they're not financial
                        advisors. There are a number of organisations you can seek free and independent advice from,
                        such as:</p>
                        <ul>
                            <li><a href="https://www.stepchange.org/">StepChange</a> - Offers a structured programme of advice on how to manage your money.</li>
                            <li><a href="https://www.moneyhelper.org.uk/en">Money Helper</a> - Free and impartial help with money, backed by the government.</li>
                            <li><a href="https://www.citizensadvice.org.uk/">Citizens Advice</a> - Look on the Citizens Advice website for the telephone number of
                            your local bureau.</li>
                        </ul>
                        <div><br /></div>
                        <h6>Getting the right support</h6><div><br /></div>
                        <p>Whatever the reason for your financial difficulty, we’re here to help.</p>
                        <p>The support we may be able to offer is based on your circumstances. Completing a <a
                        href={ data.file.publicURL } target="_blank">budget planner</a> will help us provide you with the best support options for you to choose from </p>
                        <ul>
                            <li>You should complete a budget planner before getting in touch with us, so that we
                            can provide you with the best support options based on your circumstances. If
                            you prefer, one of our Customer Support Consultants can complete this with you
                            over the phone.</li>
                            <li>You can complete our budget planner, or supply us with one you may have
                            completed with another organisation or have completed yourself.</li>
                            <li>The completed budget planner can be sent to our email address below, posted to
                            us, or you can call us to provide the figures over the telephone.</li>
                        </ul>
                        <div><br /></div>
                        <h6>Ways we may be able to help you</h6><div><br/></div>
                        <p>We might be able to support you by:</p>
                        <strong>Stopping your payments for a short period</strong>
                        <p>Your mortgage balance won't decrease and interest will still be charged. When the payment
                        deferral ends, your monthly payment will increase to make sure you still finish your mortgage
                        on time.</p>
                        <strong>Temporarily changing your mortgage to interest only</strong>
                        <p>This will lower your monthly payments but your mortgage balance won't decrease during that
                        time. When the temporary interest only period ends and your account is changed back to
                        repayment, your new payments will be higher than they previously were.</p>
                        <strong>Temporarily extending the time you have to repay your mortgage temporarily</strong>
                        <p>This could reduce your payments for the agreed period of time. This approach would allow you
                        to continue to pay both the interest and capital of your mortgage, but with lower monthly
                        payments. When this ends, your monthly payment could increase, as you'll have less time to
                        pay it back.</p>
                        <strong>An arrangement to repay any missed payments</strong>
                        <p>If you've missed payments that you're now able to pay, we could come to an arrangement.
                        You’d pay your normal monthly payment, plus an additional amount towards the missed
                        payments every month.</p><br/>
                        <p>Everyone's circumstances are different and not every option will be suitable for you. We'll
                        always let you know what impact each option will have including how your payments may
                        change after the support ends.</p>
                        <p>For some solutions, you'll need to meet our criteria. And, whiles we can offer solutions, we
                        cannot provide you with independent advice of your mortgage and your finances.</p>





                        
                        
                    </div>
                </div>
                <div className="fbCol">
                    <div className="fbList">
                        <ul>
                            <li className="">
                                <div className="top"></div>
                                <div className="txtWrap">
                                    <h3><span style={{'display': 'none'}}>&nbsp;</span></h3>
                                    <p>
                                    </p><p>YOUR HOME MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A MORTGAGE
                                    OR ANY OTHER DEBT SECURED ON IT.</p>
                                    <p></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
)

export default HelpRepaymentPage;